<template>
    <div class="container">
        <crud ref="crud" :options="options">
            <template slot="header">
                <el-form-item>
                    <el-input v-model="options.params.keyword" clearable placeholder="广告名字"/>
                </el-form-item>
                <el-form-item>
                    <tb-select ref="position" :options="positionOptions"
                               @change="val => this.options.params.advert_position_id = val"/>
                </el-form-item>
                <el-form-item>
                    <el-button @click="$refs.crud.getLists()" icon="el-icon-search">查找</el-button>
                </el-form-item>
            </template>
        </crud>
    </div>
</template>

<script>
export default {
    name: "List",
    data: function () {
        return {
            events: ['无事件', '内部打开'],
            options: {
                addBtn: true,
                editBtn: true,
                delBtn: true,
                pager: true,
                addUrl: 'admin.Advert.add',
                addPerm: 'admin_Advert_add',
                editUrl: 'admin.Advert.edit',
                editPerm: 'admin_Advert_edit',
                delUrl: 'admin.Advert.delete',
                delPerm: 'admin_Advert_delete',
                listUrl: 'admin.Advert.page',
                formLabelWidth: '100px',
                params: {
                    advert_position_id: null,
                    keyword: null
                },
                columns: [
                    {
                        prop: "advert_position_id", label: "广告位", required: true, formatter: row => {
                            return row.position ? row.position.name : ''
                        },
                        type: 'select',
                        dicUrl: 'admin.AdvertPosition.lists',
                        dicData: [],
                        dicProps: {label: 'name', value: 'id'},
                    },
                    {prop: "name", label: "广告名称", required: true},
                    {
                        prop: "cover", label: "广告封面", required: true, type: 'image', width: 72,
                        html: ({row}) => {
                            return row.cover ? `<a target="_blank" href="${row.cover}"><img class="list-user-avatar" src="${row.cover}" alt="加载失败"/></a>` : null
                        },
                        tip: '图片大小建议尺寸710x300'
                    },
                    {
                        prop: "action", label: "事件类型", required: true, formatter: row => {
                            return this.events[row.action]
                        },
                        type: "radio",
                        dicData: [{label: '无事件', value: 0}, {label: '内部打开', value: 1}],
                        value: 0,
                    },
                    {prop: "content", label: "事件内容", tip: '非无事件时请填写事件内容'},
                    {prop: "sort", label: "排序", type: "number", required: true, value: 0},
                    {prop: "remark", label: "备注",}
                ],
            },
            positionOptions: {
                listUrl: 'admin.AdvertPosition.lists',
                filter: (item, value) => {
                    return !value || item.name.includes(value)
                },
                label: "item.name",
                placeholder: '广告位筛选'
            },
        }
    },
    activated: function () {
        this.$refs.position.getLists()
    },
    methods: {}
}
</script>

<style scoped>

</style>
